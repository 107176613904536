.search_video_page_container {
    padding: 0;
    padding-bottom: 10px;
    .search_input_container {
      margin-top: 18px;
      margin-bottom: 18px;
      padding: 0 48px 0 8px;
      background-color: $white;
      .search_input {
        height: 30px;
      }
    }
    .search_video_list_container {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
      padding: 0 3px 3px;
    }
    .search_video_list_empty {
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }
    .video_item {
      filter: drop-shadow(0px 0px 1px rgba($black, 0.5));
      height: calc((100vh - 146px) / 3);
      max-height: 230px;
      .video_item_preview {
        width: 100%;
        height: 100%;
      }
    }
    .scroll_pagination_anchor {
      display: block;
      width: 100%;
      height: 1px;
      min-height: 1px;
      margin-top: -1px;
      opacity: 0;
    }
  }
  