.back_button_container {
  display: flex;
  align-items: center;
  .back_button {
    color: $black;
    font-size: 2.4rem;
    line-height: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
  }
  .chat_room_name_title {
    font-size: 1.7rem;
    line-height: 1.6rem;
    letter-spacing: 0.1rem;
    margin-left: 3.2rem;
  }
}
