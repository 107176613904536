.chat_room_settings_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 38px;
  border-bottom: 1px solid #d9d9d9;
  .settings_name_sections {
    width: 100%;
    display: flex;
    align-items: center;
    //max-height: 60px;
    margin-bottom: 25px;
    .logo_edit_container {
      position: relative;
      margin-right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      .load_logo_input {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        z-index: 2;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .logo_container {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eeecec;
      border-radius: 50%;

      font-size: 2rem;
    }
    .setting_room_name_input_container {
      flex: 1;
    }
  }
  .bio_section_container {
    display: flex;
    flex-direction: column;

    .label_block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }
    .bio_label_text {
      font-size: 1.7rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      color: $black;
    }
    .bio_setting_input {
      //max-height: 65px;
      //overflow: hidden;
    }
  }
}
