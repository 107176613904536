.top_navigation_link {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  color: $black;
  text-decoration: none;
  &.disabled_link {
    pointer-events: none;
    opacity: 0.5;
  }
}
