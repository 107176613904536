.column {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .keyword {
    margin-bottom: 1.7rem;
  }
}

.active_tab_panel_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 7px; /// 76px for adv block????
  overflow: auto;
  .active_tab_panel_content_container {
    display: flex;
    .active_tab_panel_content_title {
      white-space: nowrap;
      margin-right: 5px;
    }
  }
  .active_tab_panel_content {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: $black;
    margin-bottom: 8px;
  }
}
