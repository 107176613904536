.menu_button {
  width: 20px;
  height: 20px;
  border: 0.15rem solid #0a292e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #0a292e;
}
