.participants_chat_room_container {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  .list_control_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    position: relative;
    overflow-x: hidden;
  }
  .participants_count_text {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #7e7e7e;
  }
  .select_all_container {
    display: flex;
    justify-content: flex-end;
    .select_button {
      opacity: 1;
      transition: opacity 0.2s;
      position: absolute;
      z-index: 1;
      &.showAll {
        opacity: 0;
        transition: opacity 0.2s;
        z-index: -1;
      }
    }
    .select_all_buttons_container {
      display: flex;
      align-items: center;
      width: max-content;
      transition: 0.2s;
      opacity: 0;
      transform: translateX(5.55rem);
      &.showAll {
        transition: 0.3s;
        opacity: 1;
        z-index: 2;
        transform: translateX(0);
      }
    }
    .select_all_button {
      margin: 0 16px;
    }
    .delete_all_button {
      font-size: 2rem;
      padding: 0;
    }
  }
  .participants_list_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .participant_item_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .participant_info_block {
      display: flex;
      align-items: center;
      flex: 0.9;
      overflow: hidden;
      .foto_container {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
      .personal_info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .participant_name_text {
          font-size: 1.6rem;
          line-height: 2.6rem;
          letter-spacing: 0.3px;
          color: $black;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .participant_nikname_text {
          font-size: 1.3rem;
          line-height: 2rem;
          letter-spacing: 0.3px;
          mix-blend-mode: normal;
          opacity: 0.5;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .participant_item_control {
      min-width: 22px;
      .delete_participant_button {
        width: 22px;
        height: 22px;
        font-size: 14px;
        color: #7e7e7e;
        background-color: #dadada;
        padding: 0;
      }
    }
  }
  .search_block {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
    .top_avatar_list_container {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .MuiAvatarGroup-avatar {
        width: 50px;
        height: 50px;
      }
      .top_avatar_participant_item {
        width: 50px;
        height: 50px;
        margin-right: 16px;
      }
    }
    .search_participants {
      height: 36px;
    }
  }
}

.confirmation_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .confirmation_modal {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 80%;
    max-width: 350px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
    position: relative;
    padding: 16px;
    .modal_close_button {
      font-size: 2.4rem;
      color: #667085;
      position: absolute;
      right: 22px;
      top: 22px;
    }
    .modal_icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #ec4f3c;
      font-size: 18px;
      background-color: #fee4e2;
      border: 8px solid #fef3f2;
      margin-bottom: 12px;
    }
    .modal_title {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: #101828;
      margin-bottom: 5px;
    }
    .modal_description {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #475467;
      margin-bottom: 24px;
    }
    .modal_button {
      height: 44px;
      border-radius: 8px;
      text-transform: none;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .delete_button {
      margin-bottom: 12px;
      box-shadow: none;
    }
  }
}
