.channel_page_container {
  position: relative;
  padding: 0;
  .top_buttons_group {
    width: 100%;
    height: 48px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0 16px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    .modal_title {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #fff;
      text-align: center;
      padding: 8px 0px 8px 0px;
      margin-right: 16px;
      z-index: 10;
    }
    .modal_title_underline {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #fff;
      text-align: center;
      padding: 8px 0px 8px 0px;
      margin-right: 16px;
      text-decoration: underline;
      text-underline-offset: 8px;
      z-index: 10;
    }
    .content_detail_button_section {
      position: absolute;
      left: -23px;
      display: flex;
      color: $white;
      &.content_detail_animate {
        transform: translateX(-26px);
        transition: transform 0.4s;
        .content_detail_title_block {
          opacity: 0;
          transition: opacity 0.2s;
        }
      }
      .content_detail_button {
        width: 48px;
        height: 48px;
        background-color: $ocean;
        color: $white;
        border-radius: 50%;
      }
    }
    .channels_modal_button {
      width: 48px;
      height: 48px;
      background-color: $ocean;
      border-radius: 50%;
      color: $white;
    }
  }
  .bottom_buttons_group {
    position: absolute;
    width: 100%;
    bottom: 44px;
    height: 48px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    .content_detail_button_section {
      position: absolute;
      left: 5px;
      display: flex;
      color: $white;
      &.content_detail_animate {
        transform: translateX(-26px);
        transition: transform 0.4s;
        .content_detail_title_block {
          opacity: 0;
          transition: opacity 0.2s;
        }
      }
      .content_detail_button {
        width: 48px;
        height: 48px;
        background-color: $ocean;
        color: $white;
        border-radius: 50%;
      }
      .content_detail_title_block {
        display: flex;
        flex-direction: column;
        padding-left: 14px;
        justify-content: center;
        .content_detail_title {
          font-size: 1.6rem;
          line-height: 2rem;
          padding-bottom: 6px;
          width: 250px;
          mix-blend-mode: difference;
          color: $white;
        }
        .content_detail_subtitle {
          width: 250px;
          mix-blend-mode: difference;
          color: $white;
        }
      }
    }
    .channels_modal_button {
      width: 48px;
      height: 48px;
      background-color: $ocean;
      border-radius: 50%;
      color: $white;
    }
  }
  .muted_button {
    width: 30px;
    height: 30px;
    font-size: 2.4rem;
    position: absolute;
    bottom: 137px;
    right: 24px;
    padding: 5px;
    filter: drop-shadow(0 0 0.4rem $black);
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: difference;
  }
  .empty_content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .empty_content_icon {
      margin-bottom: 12px;
    }
    .empty_content_text {
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.1rem;
      color: #000000;
    }
  }
  .channel_content_section {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    scroll-snap-type: y mandatory;

    .content_episode_container {
      position: relative;
      height: calc(100vh - 60px);
      width: 100%;
      //// video
      .content_episode_play_icon {
        width: 29px;
        height: 36px;
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        cursor: pointer;
        opacity: 0.6;
      }
      .content_episode {
        height: 100%;
        width: 100%;
        scroll-snap-align: start;
        object-fit: fill;
        pointer-events: all;
      }
      @media (min-aspect-ratio: 16/9) {
        .content_episode {
          height: auto;
        }
      }
      /// audio
      .audio_episode_preview {
        height: 100%;
        width: 100%;
        scroll-snap-align: start;
        object-fit: cover;
        pointer-events: all;
      }
    }

    .load_more_anchor {
      display: block;
      height: 1px;
      min-height: 1px;
      margin-top: -1px;
      opacity: 0;
      width: 100%;
    }
  }
}

.channel_page_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  max-height: calc(100vh - 60px);
}

.channels_modal_container {
  height: 100%;
  .channels_modal_body {
    background-color: $white;
    padding: 0px 16px 20px 16px;
    width: 100%;
    max-width: 600px;
    height: 100%;
    overflow: auto;
    .modal_title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #444444;
      text-align: center;
      position: absolute;
      background-color: white;
      width: 90%;
      padding: 16px 0px 16px 0px;
      z-index: 10;
    }
    .modal_close {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #444444;
      text-align: center;
      position: absolute;
      background-color: white;
      padding: 16px 0px 16px 0px;
      z-index: 10;
      right: 30px;
    }
    .channel_item_container {
      display: flex;
      width: 100%;
      margin-bottom: 24px;
      align-items: stretch;
      .channel_item_logo {
        width: 70px;
        height: 93px;
        border-radius: 4px;
        margin-right: 20px;
      }
      .channel_item_description_block {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .channel_item_title {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 2.5rem;
          color: $black;
          margin-bottom: 10px;
        }
        .channel_item_description {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $black;
        }
      }
    }
  }
}

.content_detail_modal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .channel_modal_body {
    background-color: $white;
    padding: 50px 16px 20px 16px;
    width: 100%;
    max-width: 600px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    .bottom_close_button {
      position: absolute;
      z-index: 10;
      bottom: 21px;
      right: -23px;
      background-color: $ocean;
      border-radius: 50%;
      color: $white;
    }
    .content_detail_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      .content_detail_description {
        display: flex;
        margin-bottom: 25px;
        .content_detail_preview {
          width: 100px;
          height: 140px;
          margin-right: 20px;
        }
        .content_detail_title_section {
          display: flex;
          flex-direction: column;
          .title_text {
            font-size: 2rem;
            line-height: 2.6rem;
            color: $dark-grey;
            margin-bottom: 4px;
          }
          .description_text {
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: $grey;
            margin-bottom: 7px;
          }
          .tag_text {
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: $grey;
          }
        }
      }
    }
  }
}

.top_navigation_section_container {
  &.MuiTabs-root {
    width: 100%;
    display: flex;
    background-color: rgba(#767680, 0.24);
    min-height: 32px;
    max-height: 32px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    z-index: 9999;
    align-items: center;
    padding: 2px 2px;
  }

  .top_navigation_button_item {
    flex: 1;

    border-radius: 7px;
    &.MuiTab-textColorPrimary {
      height: 28px;
      font-family: "IBM Plex Sans Condensed", sans-serif;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.02em;
      color: $white;
      min-height: 28px;
      //mix-blend-mode: difference;
      &.Mui-selected {
        background: rgba(99, 99, 102, 0.75);
      }
    }
  }
  &.white_style {
    .top_navigation_button_item {
      &.MuiTab-textColorPrimary {
        color: #101010;
        &.Mui-selected {
          background-color: $white;
        }
      }
    }
  }
}

.comments_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  max-height: calc(100vh - 60px);
}

.comments_modal_wrap {
  width: 100%;
  max-width: 600px;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 50px;
  .title_section {
    width: 100%;
    padding: 0 16px;
  }
  .comments_modal_title {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #242424;
  }
  .tags_container {
    padding: 16px 0;
    border-bottom: 1px solid #cccccc;
  }
  .tag_text {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #4a4a4a;
  }
  .comments_owner_modal_title_container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #cccccc;

    .comment_back_button {
      color: $black;
      padding: 5px 5px 5px 0;
      font-size: 2rem;
      margin-right: 11px;
    }
    .room_logo {
      width: 40px;
      height: 40px;
      margin-right: 22px;
    }
    .room_name {
      font-family: "IBM Plex Sans Condensed", sans-serif;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.8rem;
      color: $black;
    }
  }
  .comments_rooms_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    overflow: auto;

    .commenting_item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: unset;
      padding: 0;
      margin-bottom: 30px;
      text-align: initial;
    }
    .left_block {
      display: flex;
      align-items: center;
    }
    .commenting_logo {
      width: 64px;
      height: 64px;
      margin-right: 22px;
    }
    .commenting_item_icon {
      width: auto;
      min-width: 1.6rem;
    }
    .names_section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .name_text {
        font-family: "IBM Plex Sans Condensed", sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.8rem;
        color: $black;
        .readed_badge {
          margin-left: 4px;
          display: inline-block;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          background: #ed431d;
        }
      }
      .last_message_text {
        font-family: "IBM Plex Sans Condensed", sans-serif;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $black;
        display: -webkit-box;
        -webkit-line-clamp: 2; // <- you can change rows number
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        //margin-bottom: 4px;
        &.attachment_content {
          display: flex;
          .attachment_icon {
            margin-right: 5px;
            font-size: 1.5rem;
          }
        }
      }
      .last_message_date_text {
        font-family: "IBM Plex Sans Condensed", sans-serif;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.02em;
        color: #5a5a5a;
        text-transform: capitalize;
      }
    }
  }
}
