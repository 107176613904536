.flat_action_button_container {
  position: absolute;
  bottom: 90px;
  right: 35px;
  z-index: 100;
  .flat_action_button {
    display: flex;
    align-items: center;
    padding: 0 17px 0 12px;
    .button_icon {
      font-size: 2.4rem;
      margin-right: 8px;
      color: $white;
    }
    border-radius: 17px;
    background-color: $dark-green;
    height: 4.8rem;
    .flat_action_button_label {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: none;
      color: $white;
    }
  }
}
