.custom_tabs {
  &.MuiTabs-root {
    border-bottom: 1px solid $grey5;
    .MuiTabs-indicator {
      height: 4px;
    }
    .MuiTab-textColorPrimary {
      font-family: 'IBM Plex Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #000000;
      text-transform: none;
      padding: 10px;
      white-space: nowrap;
      letter-spacing: 0.15px;
    }
    .Mui-selected {
      font-weight: 500;
      color: $dark-green;
      letter-spacing: 0.01px;
    }
  }
}
