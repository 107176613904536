// body {
//   max-width: 600px;
//   margin: 0 auto;
// }

#root {
  width: inherit;
  height: inherit;
  display: flex;
  position: relative;
  justify-content: center;
}

.main_container {
  width: 100%;
  max-width: 600px;
  height: 100vh;
  height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.page_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background-color: $white;
  padding: 10px;
  overflow: auto;
  position: relative;
}

@media (min-aspect-ratio: 16/9) {
  .back-video {
    width: 100%;
    height: auto;
  }
}

// fonts variants

.title20 {
  font-size: 2rem;
  line-height: 2.6rem;
  color: rgba($color: $dark-blue, $alpha: 1);
}

.label14 {
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.screen-header-title17 {
  font-size: 1.7rem;
  line-height: 1.6rem;
  letter-spacing: 0.1rem;
}

.meditation-tab-text14 {
  font-size: 1.4rem;
  line-height: 1.82rem;
}

.channel-title-12 {
  font-family: "IBM Plex Sans Condensed";
  font-size: 1.2rem;
  line-height: 1.56rem;
  margin-bottom: 14px;
}

.channel-description12 {
  font-family: "IBM Plex Sans Condensed";
  font-size: 1.2rem;
  line-height: 1.56rem;
}

.chatroom-title15 {
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.chatroom-description10 {
  font-size: 1rem;
  line-height: 1.3rem;
}

.contact-name16 {
  font-size: 1.6rem;
  line-height: 2.6rem;
  letter-spacing: 0.03rem;
}

.contact-nickName13 {
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.03rem;
}

.input-placeholder11 {
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.03rem;
  font-style: italic;
}

.contact-NickName13 {
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.03rem;
}

.chat-text-message12 {
  font-size: 1.2rem;
  line-height: 1.56rem;
  letter-spacing: 0.03rem;
}

.top_navigation_title {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  letter-spacing: 1px;
  color: $black;
}

// color common class names

.black {
  color: $black;
}

.dark-grey {
  color: $dark-grey;
}

.grey {
  color: $grey;
}

.grey2 {
  color: $grey2;
}

.grey3 {
  color: $grey3;
}

.light-grey {
  color: $light-grey;
}

.light-grey2 {
  color: $light-grey2;
}

.white {
  color: $white;
}

.dark-green {
  color: $dark-green;
}

.light-green {
  color: $light-green;
}

.light-green2 {
  color: $light-green2;
}

.violet {
  color: $violet;
}

.blue {
  color: $blue;
}

.green {
  color: $green;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}
