.menu_container {
  width: 100%;
  height: 60px;
  background-color: #fafafa;
  display: flex;
  z-index: 999;
  .menu_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    color: #5a5a5a;
    &.active-item {
      color: #0a292e;
    }
    .menu_icon {
      font-size: inherit;
    }
  }
}
