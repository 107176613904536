.chat_message_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
  overflow: hidden;
  .chat_message_time {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #000000;
  }
  .chat_message_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding: 10px 10px;
    min-height: 48px;
    .chat_message_text_wrap {
      display: flex;
      flex-direction: column;
      .chat_attachment {
        margin-right: 1rem;
      }
    }
  }
  .chat_message_text {
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: inherit;
    .message_user_mention {
      color: #345de8; /// #a8bcff
    }
  }
  .sender_name {
    font-size: 1.2rem;
    line-height: 2.6rem;
    letter-spacing: 0.3px;
    margin-bottom: 7px;
  }
  &.not_owner_message {
    flex-direction: row-reverse;
    .chat_message_text_container {
      padding-top: 4px;
      background: #f5f5f5;
      border-radius: 0px 17px 17px 17px;
      color: #000000;
    }
  }
  &.owner_message {
    .chat_message_text_container {
      background: #0a292e;
      border-radius: 17px 0px 17px 17px;
      color: #ffffff;
    }
    .message_user_mention {
      color: #a8bcff;
    }
  }

  .attachment_message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 15px;
    border-radius: 17px;

    .attachment_message_content_block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .cancel_download_button {
      font-size: 2rem;
      padding: 5px;
      background-color: rgba($black, 0.25);
    }

    .attachment_icon_container {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0a292e;
      width: 44px;
      height: 44px;
      font-size: 20px;
      margin-right: 8px;
      .progress_container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .progress_indicator {
          color: #ffffff;
        }

        .progress_text {
          position: absolute;
          font-size: 1.2rem;
          color: #ffffff;
        }
      }
    }
    .attachment_file_name {
      max-width: 60%;
      font-family: "IBM Plex Sans Condensed", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #0a292e;
    }
  }

  .attachment_image {
    width: 100%;
    height: 100%;
    max-height: 30vh;
    margin-bottom: 15px;
    overflow: hidden;
    .attachment_image_iname {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .voice_message_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .voice_message_control_button {
      font-size: 2.4rem;
      padding: 0px;
      margin-right: 11px;
    }
    .voice_message_count {
      margin-left: 11px;
      background-color: $dark-green;
      .count_text {
        color: $white;
      }
      &.owner_count {
        background-color: $white;
        .count_text {
          color: $ocean;
        }
      }
    }
  }
}
